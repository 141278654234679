import { ParticipantAudioTracks } from '~twilio/components/participant-audio-tracks';

import ParticipantsRoom from './participants-room';

const Room = () => {
  return (
    <div className='flex h-full w-full'>
      <ParticipantAudioTracks />
      <ParticipantsRoom />
    </div>
  );
};

export default Room;
