import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import useTwilioAccessToken from '~features/auth/hooks/queries/use-twilio-access-token';

export const BaseURL = 'https://main.d2db8milammgza.amplifyapp.com';

const MobileAccess = () => {
  const [searchParams] = useSearchParams();
  const uid = searchParams.get('uid');
  const { data: twilioAccessToken } = useTwilioAccessToken(uid || '');
  const phoneAccessLink = useMemo(
    () => `${BaseURL}?uid=${twilioAccessToken.mobile}`,
    [twilioAccessToken.mobile]
  );

  return (
    <div className='flex aspect-square w-full items-center justify-center'>
      <QRCode
        size={256}
        style={{
          height: 'auto',
          maxWidth: '100%',
          width: '100%'
        }}
        value={phoneAccessLink}
      />
    </div>
  );
};

export default MobileAccess;
