import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from '~components/@ui/button/button/component';
import Icon from '~components/@ui/icon';
import SelectBox from '~components/@ui/text-input/select-box';
import { toast } from '~components/@ui/toast/controller';
import useTwilioAccessToken from '~features/auth/hooks/queries/use-twilio-access-token';
import { useLiveConsultation } from '~features/live-consultation/hooks/queries/use-live-consultation';
import { LocalVideoPreview } from '~twilio/components/local-video-preview';
import { ToggleAudioButton } from '~twilio/components/toggle-audio-button';
import { ToggleVideoButton } from '~twilio/components/toggle-video-button';
import { WaitingOverlay } from '~twilio/components/waiting-overlay';
import useMediaDevices from '~twilio/hooks/use-media-devices';
import useVideoContext from '~twilio/hooks/use-video-context';
import { useTwilioRoomState } from '~twilio/state';

const MicDropdown = () => {
  const { displayValue, mediaTrackSelectOptions, onDeviceChange } =
    useMediaDevices('audio');
  return (
    <SelectBox className='flex h-14 w-fit flex-row items-center justify-center gap-4'>
      <Icon name='mic_outline' className='h-6 w-6 fill-gray-700' />
      <SelectBox.Input value={displayValue.audio} placeholder='Select audio' />
      <SelectBox.Dropdown>
        <SelectBox.DropdownItemGroup
          data={mediaTrackSelectOptions}
          onItemClick={(item) => {
            if (!item.value) {
              return;
            }
            void onDeviceChange(item.value as string);
          }}
        />
      </SelectBox.Dropdown>
    </SelectBox>
  );
};

const CameraDropdown = () => {
  const { displayValue, mediaTrackSelectOptions, onDeviceChange } =
    useMediaDevices('video');
  return (
    <SelectBox className='flex h-14 w-fit flex-row items-center justify-center gap-4'>
      <Icon name='video_outline' className='h-6 w-6 fill-gray-700' />
      <SelectBox.Input value={displayValue.video} placeholder='Select camera' />
      <SelectBox.Dropdown>
        <SelectBox.DropdownItemGroup
          data={mediaTrackSelectOptions}
          onItemClick={(item) => {
            if (!item.value) {
              return;
            }
            void onDeviceChange(item.value as string);
          }}
        />
      </SelectBox.Dropdown>
    </SelectBox>
  );
};

const StandBy = () => {
  const [searchParams] = useSearchParams();
  const uid = searchParams.get('uid');
  const { data: lc, refetch } = useLiveConsultation();
  const {
    isAcquiringLocalTracks,
    isConnecting,
    localTracks,
    connect: connectTwilio
  } = useVideoContext();
  const { setError } = useTwilioRoomState();
  const { data: twilioAccessTokens } = useTwilioAccessToken(uid || '');

  const isLoading = useMemo(
    () => isAcquiringLocalTracks || isConnecting,
    [isAcquiringLocalTracks, isConnecting]
  );

  const [localAudioTracks, localVideoTacks] = useMemo(
    () => localTracks,
    [localTracks]
  );

  const connect = useCallback(async () => {
    const tokenId = lc?.room_access_token || twilioAccessTokens.tv;
    try {
      if (!tokenId) {
        toast.open({
          message: 'Room not found. Please checked your session',
          type: 'caution'
        });
        return;
      }
      void connectTwilio(tokenId);
    } catch (error: any) {
      void refetch();
      toast.open({
        message: 'Something went wrong. Please refresh to try again.',
        type: 'error'
      });
      setError?.(error);
    }
  }, [
    connectTwilio,
    lc?.room_access_token,
    refetch,
    setError,
    twilioAccessTokens.tv
  ]);

  return (
    <WaitingOverlay loading={isLoading}>
      <div className='flex h-full w-full flex-col items-center justify-center bg-white p-4'>
        <div className='flex flex-row gap-16'>
          <div className='relative flex flex-col'>
            <div className='mb-5 aspect-video w-[960px] overflow-hidden rounded-3xl'>
              <LocalVideoPreview />
            </div>
            <div className='flex h-14 w-full flex-row items-center gap-5'>
              {localAudioTracks && <MicDropdown />}
              {localVideoTacks && <CameraDropdown />}
            </div>

            <div className='absolute bottom-[120px] flex w-[960px] flex-row items-center justify-center gap-3'>
              <ToggleAudioButton />
              <ToggleVideoButton />
            </div>
          </div>
          <div className='flex min-w-[200px] flex-col justify-center gap-2'>
            <Button
              className='w-full'
              priority='primary'
              size='large'
              onClick={() => {
                void connect();
              }}
              disabled={isLoading || !twilioAccessTokens.tv}
            >
              Enter chat
            </Button>
          </div>
        </div>
      </div>
    </WaitingOverlay>
  );
};

export default StandBy;
