import { env } from '~env';

export const __ACCESS_TOKEN__ = 'DRTAIL_VET_ACCESS_TOKEN';
export const __FIREBASE_TOKEN__ = 'DRTAIL_VET_FIREBASE_TOKEN';
export const __REFRESH_TOKEN__ = 'DRTAIL_VET_REFRESH_TOKEN';

export const DEFAULT_THUMBNAIL_VET = `${env.VITE_STATIC_RES}/img/vet-avatar.webp`;
export const DEFAULT_THUMBNAIL_PET = `${env.VITE_STATIC_RES}/img/pet-default.webp`;
export const DEFAULT_THUMBNAIL_PERSON = `${env.VITE_STATIC_RES}/img/person-avatar.webp`;

export const MEDIA_FALLBACK_URL = `${env.VITE_STATIC_RES}/img/media-default.webp`;

// Twilio Constants
// export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video']
export const DEFAULT_VIDEO_CONSTRAINTS = {
  width: 1920,
  height: 1080,
  frameRate: 24
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'Dr.Tail:selected-audio-input';
export const SELECTED_AUDIO_OUTPUT_KEY = 'Dr.Tail:selected-audio-output';
export const SELECTED_VIDEO_INPUT_KEY = 'Dr.Tail:selected-video-input';
