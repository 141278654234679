import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { VideoChat } from '~pages/live-consultation/video-chat/page';
import TwilioRoomStateProvider from '~twilio/state';

const router = createBrowserRouter([
  {
    children: [
      {
        path: '/',
        element: (
          <TwilioRoomStateProvider>
            <VideoChat />
          </TwilioRoomStateProvider>
        )
      }
    ]
  }
]);

/**
 * @see https://reactrouter.com/en/main/start/tutorial
 */
const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;
