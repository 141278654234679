import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';
import { useEffect, useRef } from 'react';

export const spentTimeAtom = atomWithReset('00:00');
spentTimeAtom.debugLabel = 'spentTimeAtom';

const getSpentTime = (created_at: Date) => {
  const spentSeconds = dayjs().diff(dayjs(created_at), 'seconds');
  return new Date(spentSeconds * 1000).toISOString().slice(11, 19);
};

export const useSpentTime = () => {
  const [spentTime, setSpentTime] = useAtom(spentTimeAtom);
  const created_at = useRef<Date>(new Date()).current;

  useEffect(() => {
    setSpentTime(getSpentTime(created_at));
    const interval = setInterval(() => {
      setSpentTime(getSpentTime(created_at));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [created_at, setSpentTime]);

  const resetSpentTime = () => {
    setSpentTime(RESET);
  };

  return {
    spentTime,
    resetSpentTime
  };
};
