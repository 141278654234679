import { memo } from 'react';
import Button from '~components/@ui/button/button/component';
import Icon from '~components/@ui/dialog/icon';
import { useSpentTime } from '~twilio/hooks/use-spent-time';
import useVideoContext from '~twilio/hooks/use-video-context';
import { cn } from '~utils/tailwind';

interface TimerButtonProps {
  className?: string;
}

const TimerButton = memo(({ className }: Readonly<TimerButtonProps>) => {
  const { room } = useVideoContext();
  const { spentTime } = useSpentTime();

  return (
    <Button
      priority='destructive'
      className={cn('flex h-[44px] w-[200px] flex-row gap-4', className)}
      onClick={() => {
        room?.disconnect();
      }}
    >
      <Icon name='call' className='mt-6 h-5 w-5' />
      <p className='text-white text-title-3'>{spentTime}</p>
    </Button>
  );
});
TimerButton.displayName = 'TimerButton';
export default TimerButton;
