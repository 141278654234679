import { useQuery } from '@tanstack/react-query';
import { api } from '~utils/api';
import { env } from '~env';

const appBaseUrl = env.VITE_ABLY_HOST;

interface FetchAccessTokenParams {
  uid: string;
}

type FetchAccessTokenResponse = {
  tv: string;
  mobile: string;
};

const fetchTwilioAccessToken = async (
  params: Readonly<FetchAccessTokenParams>
) => {
  let res = {
    tv: '',
    mobile: ''
  };

  try {
    res = (
      await api.get<FetchAccessTokenResponse>('/lcs/access_token/', {
        params,
        baseURL: appBaseUrl
      })
    ).data;
  } catch (error: any) {}

  return res;
};

const useTwilioAccessToken = (uid?: string) => {
  return useQuery({
    queryKey: ['twilio-access-token', uid],
    queryFn: async () =>
      await fetchTwilioAccessToken({
        uid: uid || ''
      }),
    staleTime: 0,
    gcTime: 0,
    enabled: !!uid,
    initialData: {
      tv: '',
      mobile: ''
    }
  });
};

export default useTwilioAccessToken;
